import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GlobalService } from "src/app/services/global.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditMarketComponent implements OnInit {
  form: FormGroup;
  image_edit = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  permissions = [];
  show = false;
  constructor(
    private formbuilder: FormBuilder,
    private service: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
    this.form = this.formbuilder.group({
      home_tag_id: [this.data.id, Validators.required],
      name_ar: [this.data.name_ar, Validators.required],
      name_en: [this.data.name_en, Validators.required],
      // description_en:[this.data.description_en,Validators.required],
    });
    // this.permissions = [...this.data.roles]

    this.selectedItems = [];
 
    this.permissions = this.selectedItems;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "section_id",
      textField: "section_name",
      selectAllText: " اختيار الكل",
      unSelectAllText: " الغاء الاختيار",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    console.log("this.data");
    console.log(this.data);
  }

  files: File[] = [];

  


  submit() {
    console.log("Form Work");
    this.spinner.show();
    let form = {

      category_id: this.data.id,
      ...this.form.value,
      image: this.files[0],
    };
    console.log("submitting the form", form);
    this.service.editTag(form).subscribe((res: any) => {
      console.log(res);
      this.spinner.hide();
      if (res.status == 200) {
        Swal.fire("نجاح", "تم تعديل  ", "success");
      } else {
        for (let i = 0; i < res.errors.length; i++) {
          this.toastr.error(res.errors[i]);
        }
      }
      this.dialog.closeAll();
    });
  }
}
