import { GlobalService } from 'src/app/services/global.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-show-balance',
  templateUrl: './show-balance.component.html',
  styleUrls: ['./show-balance.component.scss']
})
export class ShowBalanceComponent implements OnInit {

  constructor(private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data: any,private service:GlobalService) { }
balance:any;
amount:any;
  ngOnInit(): void {
    console.log(this.data);
    
    this.getBalance()
  }

  getBalance(){
    this.service.getBalance(this.data.user.id).subscribe((e:any)=> {console.log(e) 
      this.balance = e.data})
  }
  tarnsferBalance(value = this.amount){

    if(value){
      const obj:any = new Object(); 
           obj.user_id = this.data.user.id;
           obj.transfer_amount = value;
       this.service.transferBalance(obj).subscribe((e:any)=> {console.log(e) 
if(e.status == 200){
  Swal.fire("تم التحويل بنجاح ");
  this.dialog.closeAll()
}    
    })
    }else{
      Swal.fire("برجاء ادخال المبلغ")
    }

   
  }
}
