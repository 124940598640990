import { NgxSpinnerService } from 'ngx-spinner';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { DetailsComponent } from '../details/details.component';
import { productDetailsComponent } from '../../products/product-details/category-details.component';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  allData:any;
  name:any;
  type:any = 'منتجات';
  id:any
  storeId:any;
  data:any;
  orders:any;
  statusid:any;
  actiive:any = 0;

  // baseUrl=environment.baseURL
  constructor(private dialog:MatDialog,private spinner:NgxSpinnerService,private service:GlobalService,private route: ActivatedRoute) {
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.storeId = this.route.snapshot.queryParamMap.get('store');
   }

  ngOnInit(): void { 
    this.storeProduct();
    console.log(this.id , "poi");
    this.getStore()
    this.allOrders()
    console.log(this.id , "juhgyuagsduh");
    console.log(this.storeId , "juhgyuagsduh");

 
  
  }


 storeProduct(name = this.name){
  this.spinner.show()
  if(this.name){
    this.service.storeProduct({store_id : this.storeId , product_name : name}).pipe(map(res=>res)).subscribe(res=>{
      this.spinner.hide()
    
        this.allData=res
        console.log(res , "kidijauyh");
        
      })
  }else{
    this.service.storeProduct({store_id : this.storeId}).pipe(map(res=>res)).subscribe(res=>{
      this.spinner.hide()
    
        this.allData=res
        console.log(res , "kidijauyh");
        
      })
  }
  this.service.storeProduct({store_id : this.storeId , product_name : name}).pipe(map(res=>res)).subscribe(res=>{
  this.spinner.hide()

    this.allData=res
    console.log(res , "kidijauyh");
    
  })
 }



 searchStore(name = this.name){
  this.spinner.show()
  if(!name){

    return;
  }
  this.service.searchStore(name).pipe(map(res=>res)).subscribe(res=>{
  this.spinner.hide()
  
    this.allData=res['stores']
    console.log(this.allData);
    
  })
}

deleteApp(category_id){
  this.spinner.show()
  this.service.deleteProduct(category_id).subscribe(res=>{
    this.spinner.hide()
    Swal.fire(
      'نجاح',
      'تم حذف الفئة بنجاح',
      'success'
      )
      // this.categoryList()
  })
}


getStore(){
  this.service.getStore(this.id).pipe(map(res=>res)).subscribe(res=>{
    this.spinner.hide()
  
      this.data=res['data']
      console.log(res , "kidijauyh");
      
    })
}



allOrders(status=0,id = this.storeId,page = 1){
  this.spinner.show()
  this.service.storeOrders(status, id ,page).subscribe((res:any)=>{
    console.log('res')
    console.log(res , "ikjuytrtghyjk")
    this.orders= res.orders
    this.statusid = status
    this.spinner.hide()
    this.actiive = status
  })
}


orderDetails(user){
  let dialogRef = this.dialog.open(DetailsComponent, {
    height: '600px',
    width: '600px',
    data:user,
  });
}


viewApp(category){
  let dialogRef = this.dialog.open(productDetailsComponent, {
    data:category,
    height: '650px',
    width: '600px',
  });
}



deleteItem(id){
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  
  swalWithBootstrapButtons.fire({
    title: 'تأكيد الحذف ؟',
    text: "لن نتمكن من استرجاع المنتج بعد الحذف",
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'الغاء',
    confirmButtonText: 'حذف',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      this.deleteApp(id);
      
      swalWithBootstrapButtons.fire(
        'تم الحذف !',
      )
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'تم الالغاء',
      )
    }
  })
}





}
