import { GlobalService } from 'src/app/services/global.service';
import   Swal  from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
type:any;
allData:any;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.allunfilteredOrders(0 , 1)
  }

  allOrders(status,page){
    this.spinner.show()
    this.service.allOrders(status, page).subscribe((res:any)=>{
      console.log('res')
      console.log(res)
      this.allData= res.orders
      this.type = status
      this.spinner.hide()

    })
  }

  allunfilteredOrders(status,page){
    this.spinner.show()
    this.service.allunfilteredOrders(status, page).subscribe((res:any)=>{
      console.log('res')
      console.log(res)
      this.allData= res.orders
      this.type = status
      this.spinner.hide()
    })
  }
  deleteApp(){
    Swal.fire(
      'نجاح',
      'تم حذف التطبيق بنجاح',
      'success'
      )
  }
  orderDetails(user){
    let dialogRef = this.dialog.open(OrderDetailsComponent, {
      height: '600px',
      width: '600px',
      data:user,
    });
  }
}
