import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddMarketComponent implements OnInit {
  dropdownSettings = {};
  tags =[]
  dropdownList = [];
  selectedItems = [];

  form:FormGroup;
  constructor(private formbuilder:FormBuilder,private service:GlobalService,private spinner:NgxSpinnerService,@Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    console.log(this.data);
    
    this.tagsList()
    // this.form=this.formbuilder.group({
    //   name_ar:['',Validators.required],
    //   name_en:['',Validators.required],
    // })

    this.selectedItems = [
      
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name_ar',
      selectAllText: ' اختيار الكل',
      unSelectAllText: ' الغاء الاختيار',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }


  onItemSelect(item: any) {
    this.selectedItems.push(item)
    console.log(this.selectedItems);

  }
  onSelectAll(items: any) {
    this.selectedItems = items
    console.log(this.selectedItems);
  }
  
  
  submit(){
    console.log(this.selectedItems , "jihugyhj")
    let form={
      user_id : this.data.user_id,
      home_tags_ids:[...new Set(this.selectedItems)]
    }
        this.spinner.show()
    this.service.assignTag(form).subscribe(res=>{
    this.spinner.hide()
    Swal.fire(
        'نجاح',
        'تم إضافة التاج بنجاح',
        'success'
      )
    })
  }

  tagsList(){
    this.spinner.show()
    this.service.allTags().pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
    console.log('res')
      console.log(res)
      this.dropdownList=res
    })
  }
}
