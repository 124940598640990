import { ProductsModule } from './components/products/product.module';
import { UsersModule } from './components/users/users.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WrongRouteComponent } from './components/auth/errors/wrong-route/wrong-route.component';
import { LoginComponent } from './components/auth/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { CategoryModule } from './components/category/category.module';
import { OrdersModule } from './components/orders/orders.module';
import { ServicesModule } from './components/services/services.module';
import { BannersModule } from './components/banners/banners.module';
import { TagsModule } from './components/tags/tags.module';
import { VouchersModule } from './components/vouchers/vouchers.module';
import { DeliveryModule } from './components/delivery/product.module';
import { StoreModule } from './components/store/store.module';

const routes: Routes = [
  {path:'',component:LoginComponent},
  {path:'auth/login',component:LoginComponent},
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      // {path:'',component:HomeComponent, data: { title: 'الصفحة الرئيسية' }},
      {path:'home',component:HomeComponent, data: { title: 'الصفحة الرئيسية' }},
    ]
  },
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    children: [
      {path:'categories',loadChildren:()=>CategoryModule},
      {path:'users',loadChildren:()=>UsersModule},
      {path:'orders',loadChildren:()=>OrdersModule},
      {path:'stores',loadChildren:()=>StoreModule},
      {path:'services',loadChildren:()=>ServicesModule},
      {path:'banners',loadChildren:()=>BannersModule},
      {path:'tags',loadChildren:()=>TagsModule},
      {path:'vouchers',loadChildren:()=>VouchersModule},
      {path:'products',loadChildren:()=>ProductsModule},
      {path:'delivery',loadChildren:()=>DeliveryModule},
    ]
  },
  {
    path        : '**',
    pathMatch   : 'full',
    component   : WrongRouteComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
