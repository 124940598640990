import { ClientOrdersComponent } from './../client-orders/client-orders.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { UserDetailsComponent } from '../user-details/user-details.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {
  name:any;
  actiive:any = 1
  public selectedRole = this.route.snapshot.paramMap.get('role');
  public users = [
    {
      
    }
  ]
  constructor( 
    public route: ActivatedRoute,
    private spinner:NgxSpinnerService,
    private service:GlobalService,
    private dialog:MatDialog
    ) { }

  ngOnInit(): void {
    this.getUsers(1)
  }
  getUsers(id){
    this.spinner.show()
    this.service.allUsers(1,id).pipe(map(res=>res)).subscribe((response:any)=>{
      console.log(response)
      this.users = response.data
    this.spinner.hide();
    this.actiive = id
    })
  }

  viewUser(user){
    let dialogRef = this.dialog.open(UserDetailsComponent, {
      data:user,
      height: '550px',
      width: '500px',
    });
  }

  viewOrders(user){
    let dialogRef = this.dialog.open(ClientOrdersComponent, {
      data:user,
      height: '700px',
      width: '700px',
    });
  }

blockUser(id , status){
  this.service.blockUser({user_id : id,status_id : status}).pipe(map(res=>res['data'])).subscribe((response:any)=>{
    console.log(response)
    this.getUsers(1)
  this.spinner.hide()
  })
}


searchUser(query = this.name){
  if(this.name){
     this.service.searchUser(query).pipe(map(res=>res)).subscribe((response:any)=>{
    console.log(response)
    this.users = response.data
  this.spinner.hide()
  })
  }else{
    this.getUsers(1)
  }
 
}

}
