import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(private http:HttpClient) { }

  // Category

  allCategories(){
    return this.http.get(`${environment.endpoint}/user/categories/all`)
  }
  addCategory(f){
    const formData:FormData = new FormData()
    formData.append('name_en',f.name_en)
    formData.append('name_ar',f.name_ar)
    formData.append('image',f.image)
    return this.http.post(`${environment.endpoint}/admin/category/create`,formData)
  }
  deleteCategory(category_id){
    return this.http.delete(`${environment.endpoint}/admin/category/delete?category_id=${category_id}`)
  }

  editCategory(f){
    const formData:FormData = new FormData()
    formData.append('category_id',f.category_id)
    formData.append('name_en',f.name_en)
    formData.append('name_ar',f.name_ar)
    if(f.image){
      formData.append('image',f.image)
    }
    return this.http.post(`${environment.endpoint}/admin/category/update`,formData)
  }


  // Banner
  
  allBanners(){
    return this.http.get(`${environment.endpoint}/user/banners/all`)
  }
  addBanner(image){
    const formData:FormData = new FormData()
    formData.append('image',image)
    return this.http.post(`${environment.endpoint}/admin/banner/create`,formData)
  }
  deleteBanner(Banner_id){
    return this.http.delete(`${environment.endpoint}/admin/banner/delete?banner_id=${Banner_id}`)
  }



  // Tag
  
  allTags(){
    return this.http.get(`${environment.endpoint}/user/home-tags/all`)
  }

  storesOnTag(id){
    return this.http.get(`${environment.endpoint}/admin/home-tag/stores/show?home_tag_id=${id}`)

  }
  addTag(f){
    const formData:FormData = new FormData()
    formData.append('name_en',f.name_en)
    formData.append('name_ar',f.name_ar)
    return this.http.post(`${environment.endpoint}/admin/home-tag/create`,formData)
  }
  deleteTag(Tag_id){
    return this.http.delete(`${environment.endpoint}/admin/home-tag/delete?home_tag_id=${Tag_id}`)
  }
  deleteStoreFromTag(user_id , tag_id){
    return this.http.delete(`${environment.endpoint}/admin/home-tag/store/delete?user_id=${user_id}&home_tag_id=${tag_id}`)
  }

  editTag(f){
    const formData:FormData = new FormData()
    formData.append('home_tag_id',f.category_id)
    formData.append('name_en',f.name_en)
    formData.append('name_ar',f.name_ar)
    if(f.image){
      formData.append('image',f.image)
    }
    return this.http.post(`${environment.endpoint}/admin/home-tag/update`,formData)
  }

  assignTag(f){
    const formData = new FormData()
    formData.append('user_id',f.user_id)

    for (let i = 0; i < f.home_tags_ids.length; i++) {
      formData.append(`home_tags_ids[${i}]`,f.home_tags_ids[i].id)
      
    }   
    return this.http.post(`${environment.endpoint}/admin/home-tag/store/append`,formData)
  }


  // vouchers
  
  allVouchers(){
    return this.http.get(`${environment.endpoint}/admin/vouchers/all`)
  }
  addVoucher(f){

    const formData:FormData = new FormData()
    formData.append('name',f.name)
    formData.append('discount_percentage',f.discount)
    formData.append('expired_at',f.expired_at)
    return this.http.post(`${environment.endpoint}/admin/voucher/create`,formData)
  }
  deleteVoucher(voucher_id){
    return this.http.delete(`${environment.endpoint}/admin/voucher/delete?voucher_id=${voucher_id}`)
  }

  editVoucher(f){
    const formData:FormData = new FormData()
    formData.append('voucher_id',f.voucher_id)
    formData.append('name',f.name)
    formData.append('discount_percentage',f.discount)
    formData.append('expired_at',f.expired_at)
    return this.http.post(`${environment.endpoint}/admin/voucher/update`,formData)
  }

  //All Users
  allUsers(type_id,status_id){
    return this.http.get(`${environment.endpoint}/admin/show/users?type_id=${type_id}&status_id=${status_id}`)
  }


getStore(id){
  return this.http.get(`${environment.endpoint}/admin/user/details/show?user_id=${id}`)
}

searchStore(name){
  return this.http.get(`${environment.endpoint}/admin/stores/search?store_name=${name}`)
}

searchUser(name){
  return this.http.get(`${environment.endpoint}/admin/clients/name/search?name=${name}`)
}


activeStopStore(store){
  return this.http.get(`${environment.endpoint}/admin/toggle/store-availability?store_id=${store}`)
  
}

  allClients(id =1){
    return this.http.get(`${environment.endpoint}/admin/show/users?type_id=${id}`)
  }
  acceptRefuseUser(user_id,status_id){
    const formData:FormData = new FormData()
    formData.append('user_id',user_id)
    formData.append('status_id',status_id)
    return this.http.post(`${environment.endpoint}/admin/check/applications`,formData)
  }

blockUser(formData){
  return this.http.post(`${environment.endpoint}/admin/check/applications`,formData)
}

  //orders
  allOrders(status,user_id , page = 1){
    return this.http.get(`${environment.endpoint}/admin/orders/all?status_id=${status}&user_id=${user_id}&page=${page}`)
  }


  allunfilteredOrders(status , page = 1){
    return this.http.get(`${environment.endpoint}/admin/orders/all?status_id=${status}&page=${page}`)
  }



  storeOrders(status ,store, page = 1){
    return this.http.get(`${environment.endpoint}/admin/orders/all?status_id=${status}&store_id=${store}&page=${page}`)
  }

   //orders
   storeProduct(body){
    return this.http.post(`${environment.endpoint}/admin/products/search` , body)
  }

  orderDetails(id){
    return this.http.get(`${environment.endpoint}/admin/order/show?order_id=${id}`)

  }

  allProducts(page = 1){
    return this.http.get(`${environment.endpoint}/products/all?page=${page}`)
  }
  productDetails(product){
    return this.http.get(`${environment.endpoint}/product/show?product_id=${product}`)

  }

deleteProduct(id){
  return this.http.delete(`${environment.endpoint}/admin/product/delete?product_id=${id}`)
}

  deliveryFee(page = 1){
    return this.http.get(`${environment.endpoint}/admin/show/delivery-fee?page=${page}`)
  }

  appPercent(page = 1){
    return this.http.get(`${environment.endpoint}/app-percent/show?page=${page}`)
  }

  setPercent(percent){
    return this.http.post(`${environment.endpoint}/admin/app-percent/set` , percent)
  }

  setDeliveryFee(dlv){
    return this.http.post(`${environment.endpoint}/admin/update/delivery-fee`,dlv)
  }


getBalance(id){
  return this.http.get(`${environment.endpoint}/user/balance/check?user_id=${id}`)
}
  

transferBalance(balance){
  return this.http.post(`${environment.endpoint}/admin/balance/transfer` , balance)
}

}

