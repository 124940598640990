import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {
  
  public selectedRole = this.route.snapshot.paramMap.get('role');
  public users = [
    {
      
    }
  ]
  constructor( public route: ActivatedRoute,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    // this.getUsers(this.selectedRole)
  }
  getUsers(role){
    // this.spinner.show()
    // this.selectedRole = role
    // this.service.list(role).subscribe((response:any)=>{
    //   console.log(response.data)
    //   this.users = response.data
    // this.spinner.hide()
    console.log(role)

    // })
  }
}
