import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { ListComponent } from './list/list.component';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { DetailsComponent } from './details/details.component';


@NgModule({
  declarations: [ListComponent,OrderDetailsComponent, DetailsComponent],
  imports: [
    CommonModule,
    StoreRoutingModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    SharedModule
  ]
})
export class StoreModule { }
