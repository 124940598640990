import   Swal  from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditComponent } from '../edit/edit.component';
import { productDetailsComponent } from '../product-details/category-details.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  active=1;
  products;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.allProducts(1)
  }

  allProducts(page){
    this.spinner.show()
    this.service.allProducts(page).pipe(map(res=>res['data'])).subscribe(res=>{
      console.log('res')
      console.log(res)
      this.products=res
      this.spinner.hide()
    })
  }
  // deleteApp(category_id){
  //   this.spinner.show()
  //   this.service.deleteVoucher(category_id).subscribe(res=>{
  //     this.spinner.hide()
  //     Swal.fire(
  //       'نجاح',
  //       'تم حذف الكود بنجاح',
  //       'success'
  //       )
  //       this.allProducts()
  //   })
  // }
  viewApp(category){
    let dialogRef = this.dialog.open(productDetailsComponent, {
      data:category,
      height: '650px',
      width: '600px',
    });
  }

  editPackage(category){
    console.log(category)
    let dialogRef = this.dialog.open(EditComponent, {
      data:category,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.allProducts(1)
    });
  }


deleteItem(id){
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  
  swalWithBootstrapButtons.fire({
    title: 'تأكيد الحذف ؟',
    text: "لن نتمكن من استرجاع المنتج بعد الحذف",
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: 'الغاء',
    confirmButtonText: 'حذف',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      this.deleteProd(id);
      
     
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'تم الالغاء',
      )
    }
  })
}

deleteProd(id){
  this.service.deleteProduct(id).subscribe((res:any)=>{
    if(res.status != 200
      ){
      Swal.fire(res.errors[0]);
      return ;
    }else{  
        Swal.fire("تم الحذف")
    this.allProducts(1)
    }
    this.spinner.hide()

    console.log('res')
    return res
    })
}

}
