import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class productDetailsComponent implements OnInit {

  constructor(private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,private service:GlobalService,private spinner:NgxSpinnerService) { }
  product:any
  ngOnInit(): void {
    this.allProducts(this.data.id)
  }
  
  closeCard(){
    this.dialog.closeAll()
  }
  
  allProducts(page){
    this.spinner.show()
    this.service.productDetails(page).pipe(map(res=>res['data'])).subscribe(res=>{
      this.product=res
      this.spinner.hide()
    })
  }
}
