import   Swal  from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CategoryDetailsComponent } from '../category-details/category-details.component';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditComponent } from '../edit/edit.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  categories;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.categoryList()
  }

  categoryList(){
    this.spinner.show()
    this.service.allCategories().pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
    console.log('res')
      console.log(res)
      this.categories=res
    })
  }
   deleteApp(category_id){

     this.spinner.show()

    return new Promise( (resolve,reject)=> this.service.deleteCategory(category_id).subscribe(res=>{
      this.spinner.hide()
      this.categoryList()
      
    })
   )

    // this.spinner.show()
    // this.service.deleteCategory(category_id).subscribe( async res=>{
    // })
  }
  viewApp(category){
    let dialogRef = this.dialog.open(CategoryDetailsComponent, {
      data:category,
      height: '380px',
      width: '400px',
    });
  }


  editPackage(category){
    console.log(category)
    let dialogRef = this.dialog.open(EditComponent, {
      data:category,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.categoryList()
    });
  }





  deleteItem(id){
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    
    swalWithBootstrapButtons.fire({
      title: 'تأكيد الحذف ؟',
      text: "لن نتمكن من استرجاع الفئة بعد الحذف",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'الغاء',
      confirmButtonText: 'حذف',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        
        
        swalWithBootstrapButtons.fire(
          'تم الحذف !',
        ).then(_=>{
          this.deleteApp(id)
        })
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'تم الالغاء',
        )
      }
    })
  }
  


}
