import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrdersRoutingModule } from './orders-routing.module';
import { ListComponent } from './list/list.component';
import {MatIconModule} from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { OrderDetailsComponent } from './order-details/order-details.component';


@NgModule({
  declarations: [ListComponent,OrderDetailsComponent],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
  ]
})
export class OrdersModule { }
