import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import Swal from 'sweetalert2';
import { OrderDetailsComponent } from '../../orders/order-details/order-details.component';

@Component({
  selector: 'app-client-orders',
  templateUrl: './client-orders.component.html',
  styleUrls: ['./client-orders.component.scss']
})
export class ClientOrdersComponent implements OnInit {
  type:any;
  allData:any;
    constructor(private dialog:MatDialog,private service:GlobalService,@Inject(MAT_DIALOG_DATA) public data:any) { }
  
    ngOnInit(): void {
      this.allOrders(0 ,this.data.id, 1)
    }
  
    allOrders(status,id = this.data.id,page = 1){
      this.service.allOrders(status, id ,page).subscribe((res:any)=>{
        console.log('res')
        console.log(res)
        this.allData= res.orders
        this.type = status
      })
    }
    deleteApp(){
      Swal.fire(
        'نجاح',
        'تم حذف التطبيق بنجاح',
        'success'
        )
    }
    orderDetails(user){
      let dialogRef = this.dialog.open(OrderDetailsComponent, {
        height: '600px',
        width: '600px',
        data:user,
      });
    }

}
