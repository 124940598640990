import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,   private service:GlobalService) { }

  allData:any;
  ngOnInit(): void {
    console.log(this.data)
    console.log('user')
    this.getUsers()
  }

  getUsers(){
    this.service.getStore(this.data.id).subscribe((response:any)=>{
this.allData = response?.data
console.log(this.allData);


    })
  }

}
