import   Swal  from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EditComponent } from '../edit/edit.component';
import { productDetailsComponent } from '../product-details/category-details.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  active=1;
  delivery;
  appPercent;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.deliveryFee(1)
    this.apPercent(1)
  }

  deliveryFee(page){
    this.spinner.show()
    this.service.deliveryFee(page).pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()
    console.log('res')
      console.log(res)
      this.delivery=res
    })
  }

  apPercent(page){
    this.spinner.show()
    this.service.appPercent(page).pipe(map(res=>res)).subscribe(res=>{
    this.spinner.hide()
    console.log('res')
      console.log(res , "koihi")
      this.appPercent=res['data']
      if(res['data'] == null){
      this.appPercent=0

      }
    })
  }
  // deleteApp(category_id){
  //   this.spinner.show()
  //   this.service.deleteVoucher(category_id).subscribe(res=>{
  //     this.spinner.hide()
  //     Swal.fire(
  //       'نجاح',
  //       'تم حذف الكود بنجاح',
  //       'success'
  //       )
  //       this.allProducts()
  //   })
  // }
  viewApp(category){
    let dialogRef = this.dialog.open(productDetailsComponent, {
      data:category,
      height: '380px',
      width: '400px',
    });
  }

  editPackage(){
    console.log()
    let dialogRef = this.dialog.open(EditComponent, {
      data:{
         percent : this.appPercent,
         delivery : this.delivery
      },
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.deliveryFee(1)
      this.apPercent(1)
    });
  }
}
