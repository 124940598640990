import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { GlobalService } from "src/app/services/global.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-edit",
  templateUrl: "./edit.component.html",
  styleUrls: ["./edit.component.scss"],
})
export class EditComponent implements OnInit {
  form: FormGroup;
  image_edit = false;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  permissions = [];
  show = false;
  constructor(
    private formbuilder: FormBuilder,
    private service: GlobalService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  

    this.selectedItems = [];
 
    this.permissions = this.selectedItems;
    this.dropdownSettings = {
      singleSelection: false,
      idField: "section_id",
      textField: "section_name",
      selectAllText: " اختيار الكل",
      unSelectAllText: " الغاء الاختيار",
      itemsShowLimit: 3,
      allowSearchFilter: true,
    };
    console.log("this.data");
    console.log(this.data);
  }

  files: File[] = [];

  


  submit() {
    if(this.data.percent == '' || this.data.delivery == ''){
      Swal.fire("برجاء كتابة الحقول الفارغة")
      return;
    }
    if(this.data.delivery && this.data.percent){
      this.setPercent()
    this.setdlv()
    }
    
  }


  setdlv(){
    
    if(this.data.percent == '' || this.data.delivery == ''){
      Swal.fire("برجاء كتابة الحقول الفارغة")
      return;
    }


    if(this.data.delivery && this.data.percent){
      const form = {
        "delivery_fee" : this.data.delivery
      }
      this.service.setDeliveryFee(form).subscribe(async (res: any) => {
        console.log(res);
        this.spinner.hide();
        
        if (await res.status != 200) {
          for (let i = 0; i < res.errors.length; i++) {
            this.toastr.error(res.errors[i]);
          }
          return;
        } 
  
      
        this.dialog.closeAll();
      });
    }
   
  }


  setPercent(){
  
    if(this.data.percent == '' || this.data.delivery == ''){
      Swal.fire("برجاء كتابة الحقول الفارغة")
      return;
    }

    if(this.data.delivery && this.data.percent){
      const form = {
        "percent" : this.data.percent
      }
      this.service.setPercent(form).subscribe(async (res: any) => {
        console.log(res);
        this.spinner.hide();
        if (await res.status != 200) {
          for (let i = 0; i < res.errors.length; i++) {
            Swal.fire(res.errors[i]);
          }
          return;
        } 
        if (await res.status != 200) {
          for (let i = 0; i < res.errors.length; i++) {
            Swal.fire(res.errors[i]);
          }
        }
        this.dialog.closeAll();
      });
    }

   
  }
}
