import { GlobalService } from 'src/app/services/global.service';
import   Swal  from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailsComponent } from '../order-details/order-details.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddMarketComponent } from '../../tags/add-market/add.component';
import { ShowBalanceComponent } from 'src/app/shared/show-balance/show-balance.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
type:any;
allData:any;
name:any = '';
first_status=0;
  constructor(private dialog:MatDialog,private service:GlobalService,private spinner:NgxSpinnerService) { }

  ngOnInit(): void {
    this.Stores()
    
  }
  // allOrders(arg0: number, arg1: number) {
  //   throw new Error('Method not implemented.');
  // }

  Stores(type = 4 , status = 0){
    this.spinner.show()
    this.service.allUsers(type , status ).pipe(map(res=>res['data'])).subscribe(res=>{
    this.spinner.hide()

      this.allData=res;
      this.type = status
    })
  }


  searchStore(name = this.name,status = 0){
    this.spinner.show()
    if(!name){
      this.Stores(4,1);
      return;
    }
    this.service.searchStore(name).pipe(map(res=>res)).subscribe(res=>{
    this.spinner.hide()
    
      this.allData=res['stores']
      console.log(this.allData);
      
    })
  }
  deleteApp(){
    Swal.fire(
      'نجاح',
      'تم حذف التطبيق بنجاح',
      'success'
      )
  }
  orderDetails(user){
    let dialogRef = this.dialog.open(OrderDetailsComponent, {
      height: '600px',
      width: '600px',
      data:user,
    });
  }

  toggleActivate(id){
    this.service.activeStopStore(id).pipe(map(res=>res['data'])).subscribe(res=>{
  
        // this.allData=res
        this.Stores(4,1);
      })
  }

  assignMarket(category){
    console.log(category)
    let dialogRef = this.dialog.open(AddMarketComponent, {
      data:category,
      height: '650px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.Stores()
    });
  }
  accept(user_id){
    this.service.acceptRefuseUser(user_id,1).subscribe(res=>{
      Swal.fire(
        'نجاح',
        'تم القبول بنجاح',
        'success'
      )
      this.Stores(4,1)
    })
  }
  refuse(user_id){
    this.service.acceptRefuseUser(user_id,2).subscribe(res=>{
      Swal.fire(
        'نجاح',
        'تم الرفض بنجاح',
        'success'
      )
      this.Stores(4,1)
    })



    
  }


  viewBalance(user){
    let dialogRef = this.dialog.open(ShowBalanceComponent, {
      data:user,
      height: '550px',
      width: '500px',
    });
  }


  
}
