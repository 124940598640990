import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  // baseUrl=environment.baseURL
  constructor(private dialog:MatDialog,@Inject(MAT_DIALOG_DATA) public data:any,private service:GlobalService) { }
allData:any;
quantity:any;
  ngOnInit(): void {
    this.orderDetails()
    console.log('this.data')
    console.log(this.data)
  }


 orderDetails(){
  this.service.orderDetails(this.data.id).subscribe((res:any)=>{
    console.log('res')
    this.quantity = res.quantity
    this.allData= res.order.order_items
    console.log(this.allData , "hena ??")
  })
 }

}
