import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TagsRoutingModule } from './tags-routing.module';
import { AddComponent } from './add/add.component';
import { ListComponent } from './list/list.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditComponent } from './edit/edit.component';
import { AddMarketComponent } from './add-market/add.component';
import { ListMarketComponent } from './list-market/list.component';
import { EditMarketComponent } from './edit-market/edit.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';


@NgModule({
  declarations: [AddComponent, ListComponent,EditComponent,AddMarketComponent,ListMarketComponent,EditMarketComponent],
  imports: [
    CommonModule,
    TagsRoutingModule,
    NgxDropzoneModule,
    MatIconModule,
    FormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    ReactiveFormsModule
  ]
})
export class TagsModule { }
